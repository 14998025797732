<template>
    <v-container>
        <div class="d-flex flex-column justify-space-between align-center">
          <v-img :src="require('../assets/BQ_Awards.png')" contain height="150" />
          <v-img
              :src="require('../assets/check-icon.png')"
              class="my-10"
              contain
              height="100"
              width="100"
            />
            <h1>Thank You!</h1>
            <p></p>
            <h1>Payment Sucessful</h1>
            <p></p>
            <h3 style="color: #962283" class="display-0 font-weight-bold mb-1">
                Your Reference is:
            </h3>
            <p></p>
            <h3>{{ticket.charge_token}}</h3>
            <p></p>
            <p></p>
            <p></p>
            <v-btn @click="EmailConfirmation" color='#EDBC7B'>Email Confirmation</v-btn>
        </div>
        <!-- <div v-html="message"></div> -->
    </v-container>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      message: "",
      ticket: null,
      ticketholder: null,
      additionaltickets: [],
      showothertickets: false,
      ifDietary: false
    };
  },
  created() {
    this.ticket = JSON.parse(localStorage.getItem("Tickets"));
    this.ticketholder = `${this.ticket.Firstname} ${this.ticket.Surname}` ;
    this.ifDietary = this.ticket.Dietary_Requirements
    // if Multi Person then add details for each
    if (this.ticket.Multi_Person && this.ticket.Multi_Person.length > 0) {
      this.ticket.Multi_Person.forEach(element => {
        this.additionaltickets.push(element);
      });
    }
    if (this.additionaltickets.length > 0) {
      this.showothertickets = true;
    }
    this.GetMessage()
  },
  methods: {
    GetMessage() {
      this.message = `
            <div class="d-flex flex-column justify-space-between align-center">
            <h3>Thank you for purchasing ${this.ticket.Description}</h3>
            <p></p>
            <p>Please check the information below to make sure your purchase information is correct!</p>
            <p>If there are any errors, please contact the vendor by replying to this email.</p>
            <p></p>
            <h3 style="color: #962283" class="display-0 font-weight-bold mb-1">
                Payment was Sucessful and your Reference is:
            </h3>
            <p></p>
            <h3>${this.ticket.charge_token}</h3>
            <p></p>
            <h3 style="color: #962283" class="display-0 font-weight-bold mb-1">
                Purchase Details
            </h3>
            
            <table>
                <tr>
                  <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Person</th>
                  <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Email</th>
                  <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Phone</th>
                  <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Units</th>
                  <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Total</th>`
              if (this.ticket.Dietary_Requirements) {
                this.message += `<th style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Dietary Requirements</th>`
              }
              
              this.message += `</tr>
                  <tr>
                    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${this.ticketholder}</td>
                    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${this.ticket.Email }</td>
                    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${this.ticket.Mobile}</td>
                    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${this.ticket.Quantity +this.ticket.Add_Units }</td>
                    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${this.ticket.Total}</td>`
            
            if (this.ticket.Dietary_Requirements) {
              this.message += `<td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${this.ticket.Dietary_Requirements}</td>`
            }
              
            this.message += `     </tr>
                              </table >
                            </div>` ;

            if (this.showothertickets) {
              this.message += `<div class="d-flex flex-column justify-space-between align-center">
                <h3 style="color: #962283" class="display-0 font-weight-bold mb-1 mt-5">
                    Additional Guest Tickets:
                </h3>
                <p></p>
                <table>
                    <tr>
                      <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Guest's Name</th>
                      <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Dietary Requirements</th>
                    </tr>`

                this.additionaltickets.forEach(element => {
                  this.message +=
                    `
                    <tr  v-for="ticket in additionaltickets" :key="ticket.Person">
                      <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${element.Multi_Person_Name}</td>
                      <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${element.Multi_Person_Diet}</td>
                    </tr>`
                });
                this.message += `
                  </table>
                  <p>We trust you enjoy your purchase.</p>
                  </div>` ;
            }
    },
    EmailConfirmation() {
      let emailDetails = {
        "User_Name": "Events", 
        "User_Email": "marketing@basketballqld.net.au", 
        "emailstosend": this.ticket.Email, 
        "Subject": this.ticket.Description, 
        "Message": this.message 
      }
      const baseURL = "https://server.ownurgoal.net.au/coms/SendEmail";
      // Send Confirmation email
      axios
          .post(baseURL, emailDetails)
          .then(() => {
            this.$toast.success(`Email Sent Successfull!`, {
                timeout: 1000,
            });
          })
          .catch((error) => {
              this.$toast.error(`Email Failed: ${error}`, {
                  timeout: 1000,
              });
          });
    }
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
</style>